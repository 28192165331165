<template>
    <div class="main">
        <div class="inquiry-detail">
            <div class="title">
               <h3>
                 {{$t("lesson.inquiry.page_title")}}
               </h3>
            </div>
            <div class="body">
                <div v-if="success">
                    <p class="text-pre-line text-center pt-5">
                        {{$t("lesson.inquiry.msg_sent")}}
                    </p>
                    <p class="text-center mt-3 mb-3">
                        <router-link to="/">
                            <button class="btn btn-primary">
                                TOP へ
                            </button>
                        </router-link>
                    </p>
                </div>
                <div class="list" v-else>
                    <FormView @submit="onSubmit">
                        <div class="row">
                            <div class="col-lg-12">
                                <div :class="`alert alert-${msgType}`" role="alert" v-if="msg">
                                    {{msg}}
                                </div>
                            </div>
                            <div class="col-lg-12 mb-3">
                                <div class="row mb-0">
                                    <div class="col-md-3">
                                        <label>{{ $t('lesson.inquiry.teacher') }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{data.teacher_name}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 mb-3">
                                <div class="row mb-0">
                                    <div class="col-md-3">
                                        <label>{{ $t('lesson.inquiry.title') }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{data.title}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 mb-3 group-form">
                                <Input
                                    :label="$t('lesson.inquiry.subject')"
                                    name="subject"
                                    rules="required|max:250"
                                    v-model="data.subject"
                                />
                            </div>
                            <div class="col-lg-12 mb-3 group-form">
                                <TextArea
                                        errorLabel="お問い合わせ内容"
                                        :label="$t('lesson.inquiry.detail')"
                                        :placeholder="$t('lesson.inquiry.detail_placeholder')"
                                        name="detail"
                                        :required="true"
                                        v-model="data.content"
                                    />
                            </div>
                            <div class="col-lg-12 mb-3 text-center">
                                <button 
                                    class="btn btn-primary" 
                                    type="submit"
                                    :disabled="submit">
                                {{$t('lesson.inquiry.btn_send')}}{{submit?'...':''}}
                                </button>
                            </div>
                        </div>
                    </FormView>
                </div>
            </div>
        </div>
        <MetaTags/>
    </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    name: "InquiryToTeacher",
    data() {
        return {
            data: {
                teacher_name: "",
                title: "",
                lesson_id: "",
                teacher_member_id: "",
                subject: "",
                content: ""
            },
            msgType: "danger",
            msg: "",
            submit: false,
            success: 0,
        };
    },
    async created() {
        this.success = 0;
        const memberId = this.getMemberId();
        if (!memberId) {
            window.location.href = "/";
        }
        await this.getLessonInquiry({
            params: {
                lessonId: this.$route.params.lessonId,
            },
            cb: (response) => {
                let { meta, data } = response;
                if(meta && meta.code == 200){
                   let teacher = data.teacher;
                   if(teacher.member_id == this.getMemberId()){
                        this.$router.back();
                   }
                   this.data = {
                        ...this.data,
                        teacher_name: teacher.name,
                        teacher_member_id: teacher.member_id,
                        lesson_id: data.id,
                        title: data.title
                   }
                }else{
                    this.$router.back();
                }
            }
        })
    },
    methods: {
        ...mapActions({
            getLessonInquiry: "lessonDetail/getLessonInquiry",
            inquiryToTeacher: "lessonDetail/inquiryToTeacher"
        }),
        onSubmit(){
            if(this.submit)return;
            this.submit = true;
            this.inquiryToTeacher({
                data: this.data,
                cb: (response) => {
                    let { meta, data } = response;
                    if(meta && meta.code == 200){
                        this.success = 1;
                        this.msg = "";
                    }else{
                        if(data && data.message){
                            this.msg = data.message
                        }else{
                            this.msg = this.$t("lesson.inquiry.failed");
                        }
                        this.msgType = "danger"
                    }
                }
            })
            this.submit = false;
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
    },
}
</script>
<style scoped>
.card-body{
    min-height: 75vh;
}
</style>